import React, { useEffect } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

import PageHome from "@pages/Page_Home";
import PageResult from "@pages/Page_Result";
import PageAudition from "@pages/Page_Audition";

import useKarrotUser from "@hooks/useKarrotUser";
import { styled } from "@styles/stitches.config";
import { useUserStore } from "@stores/user-store";
import { isDateTodayOrInPast } from "@utils/common-util";

import Fade from "@components/_common/Fade";

import { TransitionTimeout } from "@type";

const routes: Array<{ path: string; Component: React.FC; fadeTransitionTimeout?: TransitionTimeout }> = [
  { path: "/", Component: PageHome, fadeTransitionTimeout: { enter: 100, exit: 0 } },
  { path: "/result", Component: PageResult, fadeTransitionTimeout: { enter: 100, exit: 100 } },
  { path: "/audition", Component: PageAudition, fadeTransitionTimeout: { enter: 0, exit: 100 } },
];

const App: React.FC = () => {
  const getKarrotUser = useKarrotUser();
  const setUser = useUserStore((state) => state.setUser);

  useEffect(() => {
    getKarrotUser().then(setUser);

    if (isDateTodayOrInPast(new Date("2021-10-01"))) {
      window.location.replace("https://www.daangn.com/articles/286468911");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDateTodayOrInPast(new Date("2021-10-01"))) {
    //추후에 추가할예정
    return <></>;
  }

  return (
    <>
      <Router>
        {routes.map(({ path, Component, fadeTransitionTimeout }) => (
          <Route key={path} exact path={path}>
            {({ match }) => (
              <Fade in={match !== null} timeout={fadeTransitionTimeout}>
                <Component />
              </Fade>
            )}
          </Route>
        ))}
      </Router>
      <LoadFont>load font</LoadFont>
    </>
  );
};

const LoadFont = styled("div", {
  fontFamily: "$gothicssi",
  opacity: 0,
  position: "absolute",
});

export default App;
