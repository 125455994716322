import "./styles/global.css";
import "@ungap/global-this";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const main = () =>
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );

const browserSupportsAllFeatures = () => {
  return Object.fromEntries;
};

const loadScript = (src, done) => {
  const js = document.createElement("script");
  js.src = src;
  js.onload = function () {
    done();
  };
  js.onerror = function () {
    done(new Error("Failed to load script " + src));
  };
  document.head.appendChild(js);
};
if (browserSupportsAllFeatures()) {
  main();
} else {
  loadScript("https://polyfill.io/v3/polyfill.min.js?features=Object.fromEntries", main);
}

reportWebVitals();
