export type GoodsItemId = `goods-${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}`;

export interface GoodsItem {
  id: GoodsItemId;
  name: string;
  imageUrl: string;
  thumbnailImageUrl: string;
}

export enum AuditionRound {
  QuarterFinal = 8,
  SemiFinal = 4,
  Final = 2,
}

export type AuditionBattleItemType = "itemA" | "itemB";

export interface AuditionBattle {
  winnerItemType?: AuditionBattleItemType;
  itemA: GoodsItem;
  itemB: GoodsItem;
}

export type TransitionTimeout =
  | number
  | {
      appear?: number;
      enter?: number;
      exit?: number;
    };
