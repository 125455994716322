import React from "react";

const IconVs: React.FC = () => {
  return (
    <svg width="64" height="50" viewBox="0 0 64 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.8209 15.8123C38.1031 14.6398 39.8816 14 41.9058 14C44.2834 14 46.3703 14.6625 48.152 15.877C48.7467 16.2572 49.3293 17.0418 49.3293 18.1465C49.3293 19.6413 48.212 20.7923 46.8424 20.7923C46.3255 20.7923 45.9081 20.6393 45.5164 20.3936L45.5146 20.3925C44.1946 19.5573 43.0054 19.1186 41.8521 19.1186C41.1383 19.1186 40.6609 19.2951 40.3777 19.5167C40.1078 19.7277 39.9925 19.9923 39.9925 20.2819V20.3396C39.9925 20.6069 40.033 20.7721 40.0977 20.8959C40.1624 21.0195 40.281 21.1582 40.5282 21.3154C41.0541 21.6498 41.9841 21.9635 43.6265 22.4161L43.6287 22.4167C45.5517 22.9556 47.145 23.6179 48.2585 24.6529C49.4062 25.7198 50 27.14 50 29.0544V29.1121C50 31.2842 49.223 33.025 47.8666 34.2106C46.5241 35.3841 44.6645 35.9711 42.5497 35.9711C39.971 35.9711 37.3623 35.1276 35.1715 33.4364C34.566 32.9757 34.1603 32.158 34.1603 31.2764C34.1603 29.7815 35.2776 28.6305 36.6472 28.6305C37.3281 28.6305 37.8129 28.8916 38.146 29.1424L38.1514 29.1465L38.1567 29.1507C39.5062 30.2187 40.9497 30.8525 42.6302 30.8525C43.4128 30.8525 43.9708 30.6837 44.3149 30.4414C44.6354 30.2157 44.7849 29.9214 44.7849 29.545V29.4873C44.7849 29.2675 44.7536 29.1234 44.698 29.0088C44.6427 28.8946 44.5393 28.7599 44.3197 28.6039C43.8537 28.2728 42.9939 27.9316 41.4197 27.4975C39.5342 26.9797 37.8699 26.4152 36.6838 25.4159C35.4481 24.3748 34.7774 22.9063 34.7774 20.7436V20.6859C34.7774 18.6812 35.531 16.9917 36.8209 15.8123ZM14 16.9056C14 15.3248 15.1703 14.1154 16.621 14.1154C17.9554 14.1154 18.8388 14.9645 19.2314 16.0552L23.409 27.6865L27.6423 15.9047C27.9845 14.9846 28.8966 14.1154 30.1431 14.1154C31.557 14.1154 32.7373 15.2855 32.7373 16.8479C32.7373 17.3045 32.6018 17.7623 32.4774 18.0651L26.3351 33.8744C26.0898 34.5011 25.7313 35.0377 25.2436 35.4176C24.7519 35.8006 24.1595 36 23.4894 36H23.2211C22.551 36 21.9585 35.8006 21.4668 35.4176C20.9791 35.0377 20.6207 34.5011 20.3753 33.8744L20.3747 33.873L14.2844 18.2036C14.1428 17.8381 14 17.402 14 16.9056Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="64" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="7" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.705882 0 0 0 0 0.619608 0 0 0 0.7 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default IconVs;
