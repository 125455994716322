import copy from "copy-text-to-clipboard";

import { bridge, IS_WEB } from "@bridge";

const useShare = () => {
  const share = () => {
    const oneLink = "https://daangn.onelink.me/oWdR/a99db1d8";
    if (IS_WEB) {
      copy(oneLink);
      alert("주소가 복사되었어요!");
    } else {
      bridge.share.open({ text: "당근 굿즈 오디션", url: oneLink });
    }
  };
  return share;
};

export default useShare;
