import React, { useEffect } from "react";

import { rem } from "polished";
import { useHistory } from "react-router-dom";

import useShare from "@hooks/useShare";
import { styled } from "@styles/stitches.config";
import ImageTitle from "@assets/images/img_title.png";
import { useAuditionStore } from "@stores/audition-store";

import Space from "@components/_common/Space";
import Button from "@components/_common/Button";
import Heading from "@components/_common/Heading";
import AbsolutePage from "@components/_common/AbsolutePage";
import ResultFinalChoice from "@components/result/Result_FinalChoice";
import ResultLeaderBoard from "@components/result/Result_LeaderBoard";

const PageResult: React.FC = () => {
  const { replace } = useHistory();
  const share = useShare();

  const { finalChoice } = useAuditionStore(({ finalChoice }) => ({ finalChoice }));

  const { resetAudition } = useAuditionStore(({ resetAudition }) => ({
    resetAudition,
  }));

  const onShareClick = () => {
    window.dataLayer.push({
      event: 'click_share_on_result'
    });
    share();
  };

  const onRestartClick = () => {
    resetAudition();
    replace("/");
  };

  useEffect(() => {
    if (!finalChoice) replace("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalChoice]);

  if (!finalChoice) return <></>;

  return (
    <AbsolutePage>
      <Background>
        <Heading closeButton />
        <Content>
          <ResultHeadingImage src={ImageTitle} />
          <ResultFinalChoice />
          <ResultLeaderBoard />
          <ResultButtonContainer>
            <Button type="contained" color="white" onClick={onRestartClick}>
              다시하기
            </Button>
          </ResultButtonContainer>
          <Space h={72} />
          <Button type="fixed" color="mint" onClick={onShareClick}>
            당근굿즈 오디션 공유하기
          </Button>
        </Content>
      </Background>
    </AbsolutePage>
  );
};

const Background = styled("div", {
  columnCenterX: true,
  width: "100%",
  height: "100%",
  background: "linear-gradient($navyDark, $navyLight)",
  overflowY: "scroll",
  paddingY: rem(16),
});

const Content = styled("div", {
  columnCenterX: true,
  paddingX: rem(24),
  width: "100%",
  maxWidth: "$resultWebMaxWidth",
  position: "relative",
  boxSizing: "border-box",
});

const ResultHeadingImage = styled("img", {
  marginTop: rem(16),
  marginBottom: rem(24),
  width: "100%",
  maxWidth: "90%",
});

const ResultButtonContainer = styled("div", {
  width: "100%",
  marginTop: rem(56),
  marginBottom: rem(64),
});

export default PageResult;
