import create from "zustand";

import { goodsItems } from "@db/goods-items";
import { chunkArray, shuffleArray } from "@utils/array-util";

import { AuditionBattle, AuditionBattleItemType, AuditionRound, GoodsItem } from "@type";

interface AuditionState {
  currentRound: AuditionRound;

  currentRoundBattles: Array<AuditionBattle>;
  currentBattleIndex: number;

  finalChoice: GoodsItem | null;

  setCurrentRound: (currenRound: AuditionRound) => void;

  setCurrentRoundBattles: (currentRoundBattles: Array<AuditionBattle>) => void;
  setCurrentBattleIndex: (matchIndex: number) => void;
  setCurrentBattleResult: (winnerItemType: AuditionBattleItemType) => void;

  setFinalChoice: (finalChoice: GoodsItem) => void;

  resetAudition: () => void;
  proceedToNextRound: (lastBattleWinnerItemType: AuditionBattleItemType) => void;
}

export const useAuditionStore = create<AuditionState>((set) => ({
  currentRound: AuditionRound.QuarterFinal,

  currentRoundBattles: chunkArray(shuffleArray(goodsItems), 2).map(([itemA, itemB]) => ({ itemA, itemB })),
  currentBattleIndex: 0,

  finalChoice: null,

  setCurrentRound: (currentRound) => set(() => ({ currentRound })),
  setCurrentRoundBattles: (currentRoundBattles) => set(() => ({ currentRoundBattles })),
  setCurrentBattleIndex: (currentBattleIndex) => set(() => ({ currentBattleIndex })),
  setCurrentBattleResult: (winnerItemType) =>
    set((state) => ({
      currentRoundBattles: state.currentRoundBattles.map((match, matchIndex) =>
        matchIndex === state.currentBattleIndex ? { ...match, winnerItemType } : match
      ),
      currentBattleIndex: state.currentBattleIndex + 1,
    })),

  setFinalChoice: (finalChoice) => set(() => ({ finalChoice })),

  proceedToNextRound: (lastBattleWinnerItemType) =>
    set((state) => {
      const winners = state.currentRoundBattles.map((battle) => battle[battle.winnerItemType || lastBattleWinnerItemType]);
      const currentRoundBattles = chunkArray(shuffleArray(winners), 2).map(([itemA, itemB]) => ({ itemA, itemB }));
      return { currentRoundBattles, currentRound: currentRoundBattles.length * 2, currentBattleIndex: 0 };
    }),
  resetAudition: () =>
    set(() => ({
      currentRound: AuditionRound.QuarterFinal,
      currentRoundBattles: chunkArray(shuffleArray(goodsItems), 2).map(([itemA, itemB]) => ({ itemA, itemB })),
      currentBattleIndex: 0,
      finalChoice: null,
    })),
}));
