import { bridge } from "@bridge";

const useKarrotUser = (): (() => Promise<{ nickname: string; id: string }>) => {
  const getKarrotUser = async (): Promise<{ nickname: string; id: string }> => {
    try {
      const user = await bridge?.info?.user();
      return user;
    } catch (error) {
      return null;
    }
  };

  return getKarrotUser;
};

export default useKarrotUser;
