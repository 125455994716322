import React from "react";

import { rem } from "polished";
import { keyframes } from "@stitches/react";

import { styled } from "@styles/stitches.config";

const RightSpotLight: React.FC = () => (
  <Svg width="406" height="100%" viewBox="0 0 406 820" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <g filter="url(#filter0_f)">
      <path d="M4.00006 816L4 4H37L401.293 816H4.00006Z" fill="url(#paint0_linear)" fillOpacity="0.2" />
    </g>
    <defs>
      <filter id="filter0_f" x="0" y="0" width="405.293" height="820" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
      </filter>
      <linearGradient id="paint0_linear" x1="330.444" y1="-6.14187" x2="330.444" y2="856.551" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C7F5FF" stopOpacity="0.3" />
        <stop offset="0.78125" stopColor="#8CFFF8" />
      </linearGradient>
    </defs>
  </Svg>
);

const Animation = keyframes({
  "0%": { transform: "rotate(-50deg) scale(1,1.3)", opacity: 0 },
  "33%": { transform: "rotate(-50deg) scale(1,1.3)", opacity: 0 },
  "100%": { transform: "rotate(var(--right-spotlight-angle)) scale(1,1.3)", opacity: 1 },
});

const Svg = styled("svg", {
  position: "absolute",
  top: rem(-32),
  left: `100%`,
  height: "100%",

  animation: `${Animation} 1500ms cubic-bezier(.08,.75,.29,1.02) `,
  transformOrigin: "top left",
  transform: "rotate(var(--right-spotlight-angle)) scale(1,1.3)",
});

export default RightSpotLight;
