import React from "react";

import { rem } from "polished";
import { keyframes } from "@stitches/react";

import { styled } from "@styles/stitches.config";
import { useAuditionStore } from "@stores/audition-store";

import NeonText from "@components/_common/NeonText";
import AbsolutePage from "@components/_common/AbsolutePage";

import { AuditionRound as IAudtionRound } from "@type";

const AuditionRound: React.FC = () => {
  const { currentRound } = useAuditionStore(({ currentRound }) => ({
    currentRound,
  }));

  return (
    <Background>
      <Text>{currentRound !== IAudtionRound.Final ? `${currentRound}강` : "결승"}</Text>
    </Background>
  );
};

const Background = styled(AbsolutePage, {
  background: "linear-gradient($navyDark, $navyLight)",
});

const Animation = keyframes({
  "0%": { transform: "scale(0.6)" },
  "100%": { transform: "sclae(1)" },
});

const Text = styled(NeonText, {
  fontSize: rem(74),
  animation: `${Animation} 350ms 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);`,
});

export default AuditionRound;
