export const shuffleArray = <T>(array: Array<T>): Array<T> => {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

export const chunkArray = <T>(array: Array<T>, chunkSize: number): Array<Array<T>> => {
  const chunkedArray: Array<Array<T>> = [];
  while (array.length > 0) {
    const chunk = array.splice(0, chunkSize);
    chunkedArray.push(chunk);
  }
  return chunkedArray;
};
