import React from "react";

import { rem } from "polished";
import { useHistory } from "react-router-dom";

import { bridge, IS_WEB } from "@bridge";
import { styled } from "@styles/stitches.config";

import Fade from "./Fade";
import IconBack from "./icons/Icon_Back";
import IconClose from "./icons/Icon_Close";

interface Props {
  onBack?: () => void;
  homeButton?: boolean;
  closeButton?: boolean;
}

const Heading: React.FC<Props> = ({ onBack, homeButton, closeButton }) => {
  const { push } = useHistory();

  if (!onBack && !homeButton && IS_WEB) return <></>;

  return (
    <Wrapper>
      <Button onClick={onBack}>
        <Fade in={onBack !== undefined}>
          <IconBack />
        </Fade>
      </Button>
      <Button
        onClick={() => {
          if (homeButton) {
            push("/");
          } else if (closeButton) {
            bridge.router.close();
          }
        }}
      >
        {homeButton && "처음으로"}
        {closeButton && <IconClose />}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  width: "100%",
  maxWidth: "$webMaxWidth",
  rowCenterY: true,
  justifyContent: "space-between",
  height: rem(56),
  minHeight: rem(56),
  paddingX: rem(16),
  boxSizing: "border-box",
  marginX: "auto",

  "& svg": {
    display: "flex",
  },
});

const Button = styled("div", {
  cursor: "pointer",
  color: "white",
  fontSize: "$body1",
  lineHeight: "$body1",
  fontFamily: "$roboto",
});

export default Heading;
