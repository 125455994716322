import axios from "axios";

import { GoodsItemId } from "@type";

export type VoteRecord = Record<GoodsItemId, number>;

const IS_DEV = process.env.NODE_ENV === "development";

const goodsAuditionJobsServer = axios.create({
  // cloudflare key value storage url
  baseURL: IS_DEV
    ? "https://jobs.alpha.kr.karrotmarket.com/api/goods-audition"
    : "https://jobs.kr.karrotmarket.com/api/goods-audition",
});

const goodsAuditionWorker = axios.create({
  // cloudflare key value storage url
  baseURL: "https://goods-audition.karrot.workers.dev",
});

export const getVoteRecord = async () => {
  const response = await goodsAuditionJobsServer.get<{ voteRecord: VoteRecord }>(
    "/audition"
  );
  return response.data.voteRecord;
};

export const postVoteRecord = async (id: keyof VoteRecord) => {
  const response = await goodsAuditionJobsServer.post<{ voteRecord: VoteRecord }>(
    "/audition",
    { id }
  );
  return response.data.voteRecord;
};

export const postUserId = async (userId: string) => {
  const response = await goodsAuditionWorker.post<{ userId: string }>("/user", {
    userId,
  });
  return response.data.userId;
};
