import React, { useEffect } from "react";

import { rem } from "polished";

import { goodsItemsObj } from "@db/goods-items";
import { styled } from "@styles/stitches.config";
import { useParticipants } from "@hooks/useParticipants";

import Column from "@components/_common/Column";
import NeonText from "@components/_common/NeonText";

import { GoodsItemId } from "@type";
import { numberWithCommas } from "@utils/number-util";

const ResultLeaderBoard: React.FC = () => {
  const [{ data }, dispatch] = useParticipants();

  useEffect(() => {
    dispatch({ type: "req_get" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalVotes: number = data ? Object.keys(data).reduce((sum, id) => sum + data[id as GoodsItemId], 0) : 0;

  return (
    <Wrapper>
      <Title>다른 이웃의 선택은</Title>
      <Text>
        *투표 기간이 종료되면 <br />
        최종 1위는 당근 굿즈로 제작돼요!
      </Text>
      <List>
        {data &&
          Object.keys(data)
            .sort((itemIdA, itemIdB) => data[itemIdB as GoodsItemId] - data[itemIdA as GoodsItemId])
            .map((goodsId, index) => {
              const { name, thumbnailImageUrl } = goodsItemsObj[goodsId as GoodsItemId];

              const votes = Number(data[goodsId as GoodsItemId]);
              const percent = totalVotes > 0 ? ((votes / totalVotes) * 100).toFixed(2) : 0;

              return (
                <RecordContainer key={goodsId}>
                  <RecordRank>{index + 1}</RecordRank>
                  <RecordItemContainer>
                    <RecordItemImage src={thumbnailImageUrl} alt={name} />
                    <Column>
                      <RecordItemName>{name}</RecordItemName>
                      <RecordVotePercentage>득표율 {percent}%</RecordVotePercentage>
                      <RecordVoteCount>{numberWithCommas(votes)}명 투표</RecordVoteCount>
                    </Column>
                  </RecordItemContainer>
                </RecordContainer>
              );
            })}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  fontFamily: "$roboto",
  color: "white",
  width: "100%",
});

const Title = styled("div", {
  fontSize: "$title1",
  lineHeight: "$title1",
  textAlign: "center",

  fontWeight: 700,
  marginBottom: rem(16),
});

const Text = styled("div", {
  fontSize: "$body1",
  lineHeight: "$body1",
  textAlign: "center",
  marginBottom: rem(24),
});

const List = styled("div", {
  column: true,
});

const RecordContainer = styled("div", {
  rowCenterY: true,
  width: "100%",
});

const RecordRank = styled(NeonText, {
  fontSize: rem(24),
  paddingLeft: rem(2),
  paddingRight: rem(22),
});

const RecordItemContainer = styled("div", {
  flex: 1,
  rowCenterY: true,
  paddingY: rem(24),
  borderBottom: `${rem(1)} solid rgba(255,255,255,0.15)`,
});

const RecordItemImage = styled("img", {
  width: rem(80),
  height: rem(80),
  borderRadius: rem(14),
  marginRight: rem(24),
  objectFit: "cover",
  boxShadow: "0px 2px 10px rgba(0, 180, 158, 0.5)",
});

const RecordItemName = styled("div", {
  fontSize: "$body1",
  lineHeight: "$body1",
  fontWeight: "bold",
  marginBottom: rem(4),
});

const RecordVotePercentage = styled("div", {
  fontSize: "$body2",
  lineHeight: "$body2",
  marginBottom: rem(4),
});

const RecordVoteCount = styled("div", {
  fontSize: "$caption1",
  lineHeight: "$caption1",
  opacity: 0.5,
});

export default ResultLeaderBoard;
