import React from "react";

import { styled } from "@styles/stitches.config";

import HomeBackgroundSpotLight from "./Home_SpotLights";

const HomeBackground: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <HomeBackgroundSpotLight />
      <HomeChildren>{children}</HomeChildren>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  columnCenterX: true,
  height: "100%",
  width: "100%",
  background: "linear-gradient($navySemiDark 0%, $navyLight 35%, $mintLight 100%)",
});

const HomeChildren = styled("div", {
  columnCenterX: true,
  zIndex: 1,
  height: "100%",
  width: "100%",
});

export default HomeBackground;
