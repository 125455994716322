import React from "react";

const IconClose: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <path
        d="M6.56588 5.43451C6.25346 5.12209 5.74693 5.12209 5.43451 5.43451C5.12209 5.74693 5.12209 6.25346 5.43451 6.56588L10.8688 12.0002L5.43451 17.4345C5.12209 17.7469 5.12209 18.2535 5.43451 18.5659C5.74693 18.8783 6.25346 18.8783 6.56588 18.5659L12.0002 13.1316L17.4345 18.5659C17.7469 18.8783 18.2535 18.8783 18.5659 18.5659C18.8783 18.2535 18.8783 17.7469 18.5659 17.4345L13.1316 12.0002L18.5659 6.56588C18.8783 6.25346 18.8783 5.74693 18.5659 5.43451C18.2535 5.12209 17.7469 5.12209 17.4345 5.43451L12.0002 10.8688L6.56588 5.43451Z"
        fill="white"
      />
    </svg>
  );
};

export default IconClose;
