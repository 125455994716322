import React from "react";

import { keyframes, CSS } from "@stitches/react";

import { styled } from "@styles/stitches.config";
import { rem } from "polished";

interface props {
  css: CSS;
  animationConfig?: { delay?: string; duration?: string };
}

const WhiteSparkle: React.FC<props> = ({ css, animationConfig }) => (
  <Svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={{
      ...css,
      ...(animationConfig && {
        width: rem(26),
        height: rem(26),
        transform: "translate(-50%,-50%) scale(0)",
        animation: `${Animation} ${animationConfig.duration || "1500ms"} ${animationConfig.delay || "0ms"} infinite`,
      }),
    }}
  >
    <path
      d="M5.38 0C5.38 0 5.38 5.38 0 5.38C0 5.38 5.38 5.37989 5.38 10.7599C5.38 10.7599 5.38001 5.38 10.76 5.38C10.76 5.38 5.38 5.38 5.38 0Z"
      fill="white"
    />
  </Svg>
);

const Animation = keyframes({
  "0%": { transform: "translate3d(-50%,-50%,0) scale(0)" },
  "35%": { transform: "translate3d(-50%,-50%,0) scale(1)" },
  "65%": { transform: "translate3d(-50%,-50%,0) scale(1)" },
  "100%": { transform: "translate3d(-50%,-50%,0) scale(0)" },
});

const Svg = styled("svg", {
  position: "absolute",
  transform: "translate3d(-50%,-50%,0)",
  transition: "0.3s",
});

export default WhiteSparkle;
