import React from "react";

import { styled } from "@styles/stitches.config";

import Heading from "@components/_common/Heading";
import AbsolutePage from "@components/_common/AbsolutePage";
import HomeKeyVisual from "@components/home/Home_KeyVisual";
import HomeBackground from "@components/home/Home_Background";
import HomeBottomSheet from "@components/home/Home_BottomSheet";

const PageHome: React.FC = () => {
  return (
    <AbsolutePage>
      <HomeBackground>
        <Heading closeButton />
        <Content>
          <HomeKeyVisual />
          <HomeBottomSheet />
        </Content>
      </HomeBackground>
    </AbsolutePage>
  );
};

const Content = styled("div", {
  column: true,
  height: "100%",
  width: "100%",
  maxWidth: "$webMaxWidth",
});

export default PageHome;
