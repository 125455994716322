import { styled } from "@styles/stitches.config";

const AbsolutePage = styled("div", {
  center: true,
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

export default AbsolutePage;
