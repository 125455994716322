import { TransitionTimeout } from "@type";
import React, { CSSProperties } from "react";

import { Transition, TransitionStatus } from "react-transition-group";

const defaultStyle: CSSProperties = {
  transition: `opacity 300ms`,
};

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

interface Props {
  in: boolean;
  timeout?: TransitionTimeout;
}

const Fade: React.FC<Props> = (props) => {
  return (
    <Transition in={props.in} timeout={props.timeout || 300} unmountOnExit>
      {(state) => {
        return (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {props.children}
          </div>
        );
      }}
    </Transition>
  );
};

export default Fade;
