import { useCallback, useMemo, useReducer } from "react";

import { getVoteRecord, postVoteRecord, VoteRecord } from "../api/cloudflare";

type Action =
  | {
      type: "req_get_success";
      payload: {
        voteRecord: VoteRecord;
      };
    }
  | {
      type: "req_get";
    }
  | {
      type: "req_get_failure";
      error: any;
    }
  | {
      type: "req_post_success";
      payload: {
        voteRecord: VoteRecord;
      };
    }
  | {
      type: "req_post";
      payload: {
        id: keyof VoteRecord;
      };
    }
  | {
      type: "req_post_failure";
      error: any;
    };

type State = {
  state: "hasValue" | "loading" | "error" | "standby";
  data?: VoteRecord;
  error?: any;
};

const middlewares = async (action: Action): Promise<Action | null> => {
  switch (action.type) {
    case "req_get": {
      try {
        const voteRecord = await getVoteRecord();
        return { type: "req_get_success", payload: { voteRecord } };
      } catch (error) {
        return { type: "req_get_failure", error };
      }
    }
    case "req_post": {
      try {
        const voteRecord = await postVoteRecord(action.payload.id);
        return { type: "req_post_success", payload: { voteRecord } };
      } catch (error) {
        return { type: "req_post_failure", error };
      }
    }
  }
  return null;
};

function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case "req_get_success":
    case "req_post_success":
      return { state: "hasValue", data: action.payload.voteRecord };
    case "req_get_failure":
    case "req_post_failure":
      return { state: "error", error: action.error };
    default:
      return prevState;
  }
}

export const useParticipants = (): [State, (action: Action) => void] => {
  const [state, dispatch] = useReducer(reducer, { state: "standby" });
  const dispatchWithMiddleware = useCallback(
    async (action: Action) => {
      dispatch(action);
      const nextAction = await middlewares(action);
      nextAction && dispatch(nextAction);
    },
    [dispatch]
  );

  return useMemo(() => [state, dispatchWithMiddleware], [dispatchWithMiddleware, state]);
};

export type voteRecordState = State;
