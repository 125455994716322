import React, { Fragment, useEffect, useRef, useState } from "react";

import { rem } from "polished";
import Lottie from "lottie-react";
import { keyframes } from "@stitches/react";
import { useHistory } from "react-router-dom";

import { styled } from "@styles/stitches.config";
import useCacheValue from "@hooks/useCachedValue";
import { debounce, delay } from "@utils/common-util";
import { useParticipants } from "@hooks/useParticipants";
import { useAuditionStore } from "@stores/audition-store";
import ConfettiLottieAnimation from "@lotties/confetti-lottie.json";

import Column from "@components/_common/Column";
import Heading from "@components/_common/Heading";
import IconVs from "@components/_common/icons/Icon_Vs";
import AbsolutePage from "@components/_common/AbsolutePage";

import { AuditionBattleItemType, AuditionRound } from "@type";

interface Props {
  onCurrentRoundFinish: () => void;
}

const wrapperPadding = { x: 32, y: 32 };
const itemRatio = 312 / 210;

const AuditionBattle: React.FC<Props> = ({ onCurrentRoundFinish }) => {
  const { push } = useHistory();
  const [, dispatch] = useParticipants();
  const [selectedItem, setSelectedItem] = useState<AuditionBattleItemType>();

  const vsRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { setFinalChoice, proceedToNextRound, setCurrentBattleIndex, setCurrentBattleResult } = useAuditionStore(
    ({ setFinalChoice, proceedToNextRound, setCurrentBattleIndex, setCurrentBattleResult }) => ({
      setFinalChoice,
      proceedToNextRound,
      setCurrentBattleIndex,
      setCurrentBattleResult,
    })
  );

  const { currentRound: _currentRound, currentBattleIndex, currentRoundBattles } = useAuditionStore(
    ({ currentRound, currentBattleIndex, currentRoundBattles }) => ({
      currentRound,
      currentBattleIndex,
      currentRoundBattles,
    })
  );

  const currentRound = useCacheValue(_currentRound);
  const currentBattle = useCacheValue(currentRoundBattles[currentBattleIndex], {
    invalidate: currentRoundBattles.length === currentRound / 2,
  });

  const { itemA, itemB } = currentBattle;
  const isFinalBattle = currentRound === AuditionRound.Final;

  useEffect(() => {
    const calculateItemSize = () => {
      const emptyHeight =
        (Number(wrapperRef.current?.clientHeight) -
          Number(vsRef.current?.clientHeight) -
          Number(titleRef.current?.clientHeight) -
          wrapperPadding.y * 2) /
        2;
      const emptyWidth = Number(wrapperRef.current?.clientWidth) - wrapperPadding.x * 2;

      const [itemWidth, itemHeight] =
        emptyWidth / itemRatio < emptyHeight ? [emptyWidth, emptyWidth / itemRatio] : [emptyHeight * itemRatio, emptyHeight];

      wrapperRef.current?.style.setProperty("--item-width", rem(itemWidth));
      wrapperRef.current?.style.setProperty("--item-height", rem(itemHeight));
      wrapperRef.current?.style.setProperty("--item-border-radius", rem((itemHeight * 20) / 210));
      wrapperRef.current?.style.setProperty("--item-border-width", rem((itemHeight * 6.1) / 210));
    };
    calculateItemSize();

    const onWindowResize = debounce(calculateItemSize, 50);

    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  const onWinnerSelect = async (winnerItemType: AuditionBattleItemType) => {
    setSelectedItem(winnerItemType);

    const winner = currentBattle[winnerItemType];
    const isLastBattleInCurrentRound = currentBattleIndex + 1 >= currentRoundBattles.length;

    if (isFinalBattle) {
      await delay(1500);
      dispatch({ type: "req_post", payload: { id: winner.id } });
      dispatch({ type: "req_get" });

      setFinalChoice(winner);
      push("/result");
    } else {
      await delay(1000);
      if (isLastBattleInCurrentRound) {
        onCurrentRoundFinish();
        proceedToNextRound(winnerItemType);
      } else {
        setCurrentBattleResult(winnerItemType);
        setSelectedItem(undefined);
      }
    }
  };

  return (
    <AbsolutePage>
      <Column css={{ width: "100%", height: "100%" }}>
        <Heading onBack={currentBattleIndex === 0 ? undefined : () => setCurrentBattleIndex(currentBattleIndex - 1)} homeButton />
        <Relative>
          <Wrapper ref={wrapperRef}>
            <Title ref={titleRef}>{isFinalBattle ? "최종 선택만 남았어요!" : `굿즈를 선택해주세요!`}</Title>
            <BattleContainer>
              <VsContainer ref={vsRef}>
                <IconVs />
              </VsContainer>

              {[itemA, itemB].map((item, index) => {
                const itemType: AuditionBattleItemType = index === 0 ? "itemA" : "itemB";
                return (
                  <Fragment key={item.id}>
                    <GoodsItem
                      selected={selectedItem === undefined ? undefined : selectedItem === itemType}
                      onClick={() => {
                        if (selectedItem === undefined) {
                          onWinnerSelect(itemType);
                        }
                      }}
                    >
                      <InsetShadow />
                      <OutsetShadow />
                      <GoodsItemImage src={item.imageUrl} alt={item.name} />

                      {selectedItem === itemType && isFinalBattle && (
                        <LottieContainer>
                          <Lottie animationData={ConfettiLottieAnimation} />
                        </LottieContainer>
                      )}
                    </GoodsItem>
                    {index === 0 && <Spacer />}
                  </Fragment>
                );
              })}
            </BattleContainer>
          </Wrapper>
        </Relative>
      </Column>
    </AbsolutePage>
  );
};

const Wrapper = styled("div", {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)",
  maxWidth: "$webMaxWidth",
  width: "100%",
  height: "100%",
  paddingX: rem(wrapperPadding.x),
  paddingY: rem(wrapperPadding.y),
  boxSizing: "border-box",
  columnCenter: true,
});

const Relative = styled("div", {
  column: true,
  flex: 1,
  position: "relative",
});

const Title = styled("div", {
  fontSize: rem(26),
  color: "white",
  textAlign: "center",
  fontFamily: "$gothicssi",
  paddingBottom: rem(24),
});

const BattleContainer = styled("div", {
  columnCenterY: true,
  position: "relative",
});

const VsContainer = styled("div", {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate3d(-50%,-50%,0)",
});

const OutsetShadow = styled("div", {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  borderRadius: "var(--item-border-radius)",
  boxShadow: `white 0 0px 0px var(--item-border-width), 0px 2px 20px var(--item-border-width) rgba(0, 180, 158, 0.5)`,
  zIndex: -1,
  transform: "scale(0.9)",
  opacity: 1,
  transition: "transform 250ms cubic-bezier(0.175, 0.885, 0.32, 1.12);",
});

const InsetShadow = styled("div", {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  opacity: 1,
  zIndex: 1,
  borderRadius: "var(--item-border-radius)",
  boxShadow: `inset rgba(0, 180, 158, 0.2) 0 0px 10px 6px`,
  transform: "scale(0)",
});

const FadeInFromRight1 = keyframes({
  "0%": { opacity: 0, transform: "translate3d(64px,0,0)" },
  "100%": { opacity: 1, transform: "scale(1) translate3d(0px,0,0)" },
});

const FadeInFromRight2 = keyframes({
  "0%": { opacity: 0, transform: "translate3d(64px,0,0)" },
  "20%": { opacity: 0, transform: "translate3d(64px,0,0)" },
  "100%": { opacity: 1, transform: "scale(1) translate3d(0px,0,0)" },
});

const GoodsItem = styled("div", {
  zIndex: 1,
  width: "var(--item-width)",
  height: "var(--item-height)",
  position: "relative",
  transition: "transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  userSelect: "none",
  cursor: "pointer",
  boxShadow: `0px 2px 20px rgba(0, 180, 158, 0.5)`,
  borderRadius: "var(--item-border-radius)",
  transform: "scale(1)",

  animation: `${FadeInFromRight1} 500ms`,
  "&:last-of-type": {
    animation: `${FadeInFromRight2} 600ms`,
  },

  variants: {
    selected: {
      true: {
        transform: "scale(1.03)",
        [`& > ${OutsetShadow}`]: {
          transform: "scale(0.995)",
        },
        [`& > ${InsetShadow}`]: {
          transform: "scale(1)",
        },
      },
      false: {
        transform: "scale(0.95)",
      },
    },
  },
});

const GoodsItemImage = styled("img", {
  overflow: "hidden",
  width: "var(--item-width)",
  height: "var(--item-height)",
  borderRadius: "var(--item-border-radius)",
});

const LottieContainer = styled("div", {
  boxSizing: "border-box",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "50%",
  width: "110%",
  height: "100%",
  zIndex: 1,
  transform: "translate(-50%)",

  "& > div": {
    position: "relative",
    width: "100%",
    height: "100%",

    " & > svg": {
      transform: "translate3d(0px, 0%, 0px) scale(1.5)!important",
    },
  },
});

const Spacer = styled("div", {
  paddingY: "1%",
  height: rem(54),
  minHeight: rem(54),
  maxHeight: rem(54),
});

export default AuditionBattle;
