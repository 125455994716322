import React from "react";

const IconBack: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5659 5.56588C11.8783 5.25346 11.8783 4.74693 11.5659 4.43451C11.2535 4.12209 10.7469 4.12209 10.4345 4.43451L3.43451 11.4345C3.12209 11.7469 3.12209 12.2535 3.43451 12.5659L10.4345 19.5659C10.7469 19.8783 11.2535 19.8783 11.5659 19.5659C11.8783 19.2535 11.8783 18.7469 11.5659 18.4345L5.93157 12.8002H20.0002C20.442 12.8002 20.8002 12.442 20.8002 12.0002C20.8002 11.5584 20.442 11.2002 20.0002 11.2002H5.93157L11.5659 5.56588Z"
        fill="white"
      />
    </svg>
  );
};

export default IconBack;
