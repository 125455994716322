import React from "react";

import { percentage } from "@utils/number-util";
import { styled } from "@styles/stitches.config";

import SparkleMint from "./sparkles/Sparkle_Mint";
import SparkleWhite from "./sparkles/Sparkle_White";

interface Sparkle {
  type: "white" | "mint";
  x: number;
  y: number;
  animationConfig?: { delay?: string; duration?: string };
}

/**
 *                  y = 1
 *                    |
 *                    |
 *                    |
 *                    |
 *   x = -1 ----------d----------  x = 1
 *                    |
 *                    |
 *                    |
 *                    |
 *                  y = -1
 */

const sparkles: Array<Sparkle> = [
  /**
   * LEFT-TOP
   */
  { type: "mint", x: -0.75, y: 0.3 },
  { type: "white", x: -0.6, y: 0.5, animationConfig: { duration: "1200ms", delay: "1000ms" } },
  { type: "white", x: -0.8, y: 0.8 },

  /**
   * RIGHT-TOP
   */
  { type: "white", x: 0.8, y: 0.3 },
  { type: "white", x: 0.7, y: 0.6 },
  { type: "white", x: 0.2, y: 0.75 },
  { type: "mint", x: 0.75, y: 0.8 },

  /**
   * RIGHT-BOTTOM
   */
  { type: "white", x: 0.7, y: -0.6, animationConfig: { duration: "1200ms", delay: "1600ms" } },
  { type: "mint", x: 0.9, y: -0.75 },
  { type: "white", x: 0.55, y: -0.9 },

  /**
   * LEFT-BOTTOM
   */
  { type: "white", x: -0.7, y: -0.5 },
  { type: "white", x: -0.85, y: -0.65 },
  { type: "white", x: -0.55, y: -0.9 },
];

const sparkleCss = (x: number, y: number) => {
  return { left: percentage(x * 50), top: percentage(y * -50) };
};

const HomeSparkles: React.FC = () => {
  return (
    <Wrapper>
      {sparkles.map((sparkle, i) => {
        const { type, x, y, animationConfig } = sparkle;
        switch (type) {
          case "mint":
            return <SparkleMint key={`${x},${y},${i}`} css={sparkleCss(x, y)} />;
          case "white":
          default:
            return <SparkleWhite key={`${x},${y},${i}`} css={sparkleCss(x, y)} animationConfig={animationConfig} />;
        }
      })}
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  width: "95%",
  height: "95%",
  position: "absolute",
  overflow: "visible",
  left: "50%",
  top: "50%",
});

export default HomeSparkles;
