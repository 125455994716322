import { rem } from "polished";
import { createStitches } from "@stitches/react";

export const { styled, css, theme } = createStitches({
  theme: {
    colors: {
      navy: "#2B3148",
      navyDark: "#282A3F",
      navyLight: "#395374",
      navySemiDark: "#2D2F47",
      navySemiLight: "#395475",
      mintLight: "#52E1CC",
      mintDark: "#00B49E",
      primary: "$navyDark",
      secondary: "$mintLight",
    },
    fonts: {
      system:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      gothicssi: "HGSoftGGothicssi Pro, $system",
      roboto: "Roboto, $system",
    },
    fontSizes: {
      title1: rem(24),
      title3: rem(18),
      body1: rem(16),
      body2: rem(14),
      caption1: rem(13),
    },
    lineHeights: {
      title1: rem(30),
      title3: rem(24),
      body1: rem(24),
      body2: rem(21),
      caption1: rem(19.5),
    },
    sizes: {
      webMaxWidth: rem(720),
      resultWebMaxWidth: rem(460),
    },
  },
  utils: {
    w: (value: number | string) => ({
      width: typeof value === "string" ? value : rem(value),
      minWidth: typeof value === "string" ? value : rem(value),
      maxWidth: typeof value === "string" ? value : rem(value),
    }),
    h: (value: number | string) => ({
      height: typeof value === "string" ? value : rem(value),
      minHeight: typeof value === "string" ? value : rem(value),
      maxHeight: typeof value === "string" ? value : rem(value),
    }),

    /**
     * padding
     */
    paddingX: (value: number | string) => ({
      paddingLeft: typeof value === "string" ? value : rem(value),
      paddingRight: typeof value === "string" ? value : rem(value),
    }),
    paddingY: (value: number | string) => ({
      paddingTop: typeof value === "string" ? value : rem(value),
      paddingBottom: typeof value === "string" ? value : rem(value),
    }),

    /**
     * margin
     */
    marginX: (value: number | string) => ({
      marginLeft: typeof value === "string" ? value : rem(value),
      marginRight: typeof value === "string" ? value : rem(value),
    }),
    marginY: (value: number | string) => ({
      marginTop: typeof value === "string" ? value : rem(value),
      marginBottom: typeof value === "string" ? value : rem(value),
    }),

    /**
     * flex
     */
    row: () => ({
      display: "flex",
      flexDirection: "row",
    }),
    column: () => ({
      display: "flex",
      flexDirection: "column",
    }),
    center: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    rowCenter: () => ({
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    }),
    rowCenterX: () => ({
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    }),
    rowCenterY: () => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }),
    columnCenter: () => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }),
    columnCenterX: () => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }),
    columnCenterY: () => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
  },
});
