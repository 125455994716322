import React from "react";

import { rem } from "polished";

import { styled } from "@styles/stitches.config";
import { removeNullFromObject } from "@utils/object-util";

interface Props {
  w?: number | string;
  h?: number | string;
}

const Space: React.FC<Props> = ({ w, h }) => {
  return <Wrapper css={removeNullFromObject({ w, h })}></Wrapper>;
};

const Wrapper = styled("div", {
  display: "flex",
  flexShrink: 0,
  width: rem(1),
  height: rem(1),
});

export default Space;
