import React from "react";

import { rem } from "polished";
import { useHistory } from "react-router-dom";

import useShare from "@hooks/useShare";
import { styled } from "@styles/stitches.config";

import Space from "@components/_common/Space";
import Button from "@components/_common/Button";
import { useUserStore } from "@stores/user-store";
import { postUserId } from "@api/cloudflare";

const HomeFixedBottomSheet = () => {
  const { push } = useHistory();
  const share = useShare();
  const user = useUserStore(({ id }) => ({ id }));

  const onShareClick = () => {
    window.dataLayer.push({
      event: 'click_share_on_home'
    });
    share();
  };

  const onStartClick = () => {
    const userId = user.id;

    if (userId) {
      postUserId(userId);
      window.dataLayer.push({
        event: 'click_start_on_home',
        userId
      });
    }

    push("/audition");
  };

  return (
    <Wrapper>
      <Text>
        8개 후보 중 <b>당근 굿즈</b>가 될<br /> 주인공을 선택해주세요!
      </Text>
      <Button type="contained" color="mint" onClick={onStartClick}>
        시작하기
      </Button>
      <Space h={24} />
      <Button type="underlined" onClick={onShareClick}>
        공유하기
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  column: true,
  boxSizing: "border-box",
  background: "$navy",
  width: "100%",
  borderTopLeftRadius: rem(32),
  borderTopRightRadius: rem(32),
  paddingY: rem(32),
  paddingX: rem(24),
  flexShrink: 0,
  zIndex: 1,
});

const Text = styled("div", {
  fontFamily: "$roboto",
  textAlign: "center",
  fontSize: "$title3",
  lineHeight: "$title3",
  color: "white",
  fontWeight: "bold",

  marginBottom: rem(24),

  "& > b": {
    color: "$mintLight",
  },
});

export default HomeFixedBottomSheet;
