import { rem } from "polished";

import { styled } from "@styles/stitches.config";

const Button = styled("button", {
  outline: "none",
  border: "none",
  fontWeight: "bold",
  fontFamily: "$roboto",
  cursor: "pointer",
  width: "100%",

  variants: {
    color: {
      mint: {
        background: "$mintDark",
        color: "white",
      },
      white: {
        background: "white",
        color: "$mintDark",
      },
    },
    type: {
      contained: {
        height: rem(58),
        borderRadius: rem(120),
        fontSize: "$title3",
        boxShadow: "0px 2px 10px rgba(0, 180, 158, 0.5);",
      },
      underlined: {
        background: "transparent",
        color: "white",
        textDecoration: "underline",
        fontSize: "$subtitle1",
        height: "auto",
      },
      fixed: {
        position: "fixed",
        width: "100%",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        height: rem(56),
        fontSize: "$title3",
      },
    },
  },

  defaultVariants: {
    color: "mint",
  },
});

export default Button;
