import React, { useEffect } from "react";

import { rem } from "polished";

import { styled } from "@styles/stitches.config";
import { useUserStore } from "@stores/user-store";
import { useAuditionStore } from "@stores/audition-store";

import RatioBox from "@components/_common/RatioBox";

const ResultFinalChoice: React.FC = () => {
  const finalChoice = useAuditionStore(({ finalChoice }) => finalChoice);

  const user = useUserStore(({ id, nickname }) => ({ id, nickname }));

  useEffect(() => {
    window.dataLayer.push({
      userId: user.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Text>{user.nickname ? `${user.nickname}님의 선택은` : "나의 최종 선택은"}</Text>
      <RatioBox ratio={210 / 312} css={{ background: "#41506d", borderRadius: rem(26) }}>
        <GoodsImage src={finalChoice?.imageUrl} />
      </RatioBox>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  display: "block",
  width: "100%",
  marginBottom: rem(72),
});

const Text = styled("div", {
  fontFamily: "$roboto",
  color: "white",
  textAlign: "center",
  fontSize: "$title1",
  lineHeight: "$title1",
  fontWeight: 700,
  marginBottom: rem(24),
});

const GoodsImage = styled("img", {
  width: "100%",
  borderRadius: rem(26),
  boxShadow: "0px 2px 20px rgba(0, 180, 158, 0.5)",
});

export default ResultFinalChoice;
