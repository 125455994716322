import { styled } from "@styles/stitches.config";
import { rem } from "polished";

const NeonText = styled("div", {
  fontSize: rem(100),
  color: "white",
  textShadow: "0px 0px 14px rgba(0, 180, 158, 0.7)",
  fontFamily: "$gothicssi",
  lineHeight: "135%",
});

export default NeonText;
