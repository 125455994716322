import create from "zustand";

interface UserState {
  id?: string;
  nickname?: string;
  setUser: ({ id, nickname }: { id: string; nickname: string }) => void;
}

export const useUserStore = create<UserState>((set) => ({
  setUser: (data) => set(data),
}));
