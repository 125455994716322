import React, { useEffect, useState } from "react";

import { goodsItems } from "@db/goods-items";
import { styled } from "@styles/stitches.config";
import ImageTitle from "@assets/images/img_title.png";
import { useAuditionStore } from "@stores/audition-store";

import Fade from "@components/_common/Fade";
import AbsolutePage from "@components/_common/AbsolutePage";
import AuditionBattle from "@components/audition/Audition_Battle";
import AuditionRoundDisplay from "@components/audition/Audition_RoundDisplay";

import { AuditionRound } from "@type";

const PageAudition: React.FC = () => {
  const [view, setView] = useState<"COUNT_DOWN" | "ROUND_DISPLAY" | "BATTLE">();

  const { currentRound, resetAudition } = useAuditionStore(({ currentRound, resetAudition }) => ({
    currentRound,
    resetAudition,
  }));

  useEffect(() => {
    resetAudition();
    preloadAuditionPageImages();
    showRoundDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preloadAuditionPageImages = () => {
    goodsItems.forEach(({ imageUrl }) => {
      new Image().src = imageUrl;
    });
  };

  const preloadResultPageImages = () => {
    new Image().src = ImageTitle;
    goodsItems.forEach(({ thumbnailImageUrl }) => {
      new Image().src = thumbnailImageUrl;
    });
  };

  const showRoundDisplay = async () => {
    setView("ROUND_DISPLAY");
    setTimeout(() => {
      setView("BATTLE");
    }, 1000);
  };

  return (
    <Background>
      <Fade in={view === "BATTLE"} timeout={{ enter: 350, exit: 400 }}>
        <AuditionBattle
          onCurrentRoundFinish={() => {
            if (currentRound / 2 === AuditionRound.Final) {
              preloadResultPageImages();
            }
            showRoundDisplay();
          }}
        />
      </Fade>
      <Fade in={view === "ROUND_DISPLAY"} timeout={{ enter: 200, exit: 350 }}>
        <AuditionRoundDisplay />
      </Fade>
    </Background>
  );
};

const Background = styled(AbsolutePage, {
  columnCenter: true,
  height: "100%",
  background: "linear-gradient($navyDark, $navyLight)",
});

export default PageAudition;
