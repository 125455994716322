import React from "react";

import { styled } from "@styles/stitches.config";
import ImageSrc from "@assets/images/img_title_daangni.preload.png";

import HomeBackgroundSparkles from "./Home_Sparkles";

const HomeKeyVisual: React.FC = () => {
  return (
    <Wrapper>
      <HomeBackgroundSparkles />
      <Absolute>
        <Image src={ImageSrc} />
      </Absolute>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  center: true,
  flex: 1,
  position: "relative",
});

const Absolute = styled("div", {
  center: true,
  position: "absolute",
  height: "100%",
  width: "100%",
});

const Image = styled("img", {
  maxWidth: "100%",
  maxHeight: "85%",
});

export default HomeKeyVisual;
