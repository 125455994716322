import { useEffect, useState } from "react";

const useCachedValue = <T>(value: T, config?: { invalidate?: boolean }): T => {
  const [cachedValue, setChachedValue] = useState(value);
  useEffect(() => {
    if (config?.invalidate) {
      setChachedValue(value);
    }
    // eslint-disable-next-line
  }, [value]);
  return cachedValue;
};

export default useCachedValue;
