import React from "react";

import { CSS } from "@stitches/react";
import { styled } from "@styles/stitches.config";

interface Props {
  ratio?: number;
  css?: CSS;
}

const RatioBox: React.FC<Props> = ({ children, ratio, css }) => {
  return (
    <Ratio
      css={{
        paddingTop: `calc(${ratio} * 100%)`,
        ...css,
      }}
    >
      <Absolute>{children}</Absolute>
    </Ratio>
  );
};

const Ratio = styled("div", {
  width: "100%",
  position: "relative",
});

const Absolute = styled("div", {
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
});

export default RatioBox;
