import { GoodsItem, GoodsItemId } from "@type";

import Umbrella from "@assets/images/goods/umbrella.png";
import UmbrellaThumbnail from "@assets/images/goods-thumbnail/umbrella.png";

import Ruler from "@assets/images/goods/ruler.png";
import RulerThumbnail from "@assets/images/goods-thumbnail/ruler.png";

import Mask from "@assets/images/goods/mask.png";
import MaskThumbnail from "@assets/images/goods-thumbnail/mask.png";

import Tumbler from "@assets/images/goods/tumbler.png";
import TumblerThumbnail from "@assets/images/goods-thumbnail/tumbler.png";

import Pot from "@assets/images/goods/pot.png";
import PotThumbnail from "@assets/images/goods-thumbnail/pot.png";

import Tshirt from "@assets/images/goods/tshirt.png";
import TshirtThumbnail from "@assets/images/goods-thumbnail/tshirt.png";

import Glowstick from "@assets/images/goods/glowstick.png";
import GlowstickThumbnail from "@assets/images/goods-thumbnail/glowstick.png";

import Cart from "@assets/images/goods/cart.png";
import CartThumbnail from "@assets/images/goods-thumbnail/cart.png";

export const goodsItems: Array<GoodsItem> = [
  {
    id: "goods-1",
    name: "당근 우산",
    imageUrl: Umbrella,
    thumbnailImageUrl: UmbrellaThumbnail,
  },
  {
    id: "goods-2",
    name: "당근 줄자",
    imageUrl: Ruler,
    thumbnailImageUrl: RulerThumbnail,
  },
  {
    id: "goods-3",
    name: "당근 마스크",
    imageUrl: Mask,
    thumbnailImageUrl: MaskThumbnail,
  },
  {
    id: "goods-4",
    name: "당근 텀블러",
    imageUrl: Tumbler,
    thumbnailImageUrl: TumblerThumbnail,
  },
  {
    id: "goods-5",
    name: "당근 폴딩카트 ",
    imageUrl: Cart,
    thumbnailImageUrl: CartThumbnail,
  },
  {
    id: "goods-6",
    name: "당근 화분",
    imageUrl: Pot,
    thumbnailImageUrl: PotThumbnail,
  },
  {
    id: "goods-7",
    name: "당근 야광봉",
    imageUrl: Glowstick,
    thumbnailImageUrl: GlowstickThumbnail,
  },
  {
    id: "goods-8",
    name: "당근 티셔츠",
    imageUrl: Tshirt,
    thumbnailImageUrl: TshirtThumbnail,
  },
];

export const goodsItemsObj = goodsItems.reduce((obj, item) => ((obj[item.id] = item), obj), {} as Record<GoodsItemId, GoodsItem>);
