import React, { useEffect, useRef, useState } from "react";

import SpolightLeft from "./spotlights/SpotLight_Left";
import SpotlightRight from "./spotlights/SpotLight_Right";

import { debounce } from "@utils/common-util";
import { styled } from "@styles/stitches.config";

const HomeSpotLights: React.FC = () => {
  const ref = useRef<HTMLDivElement>();
  const [isAngleCalculated, setIsAngleCalculated] = useState(false);

  useEffect(() => {
    const calculateAngle = () => {
      const bottomSheetHeight = 220;

      /* Trigonometry TOA */
      const adjacent = Number(ref.current?.clientHeight) - bottomSheetHeight;
      const opposite = Number(ref.current?.clientWidth);

      const angle = (Math.atan(opposite / adjacent) * 180) / Math.PI;

      ref.current?.style.setProperty("--right-spotlight-angle", `${angle - 2}deg`);
      ref.current?.style.setProperty("--left-spotlight-angle", `${(angle - 2) * -1}deg`);
    };

    calculateAngle();
    setIsAngleCalculated(true);

    const onWindowResize = debounce(calculateAngle, 50);

    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  return (
    <Wrapper ref={ref as any}>
      <Frame left>
        <div>{isAngleCalculated && <SpolightLeft />}</div>
      </Frame>
      <Frame right>
        <div>{isAngleCalculated && <SpotlightRight />}</div>
      </Frame>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  width: "100%",
  maxWidth: "$webMaxWidth",
  height: "100vh",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
});

const Frame = styled("div", {
  position: "absolute",
  width: "500%",
  height: "100%",
  top: 0,
  bottom: 0,
  overflow: "hidden",

  "& > div": {
    width: "20%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
  },

  variants: {
    left: {
      true: {
        right: 0,
        "& > div": {
          right: 0,
        },
      },
    },
    right: {
      true: {
        left: 0,
        "& > div": {
          left: 0,
        },
      },
    },
  },
});

export default HomeSpotLights;
