import React from "react";

import { styled } from "@styles/stitches.config";

import { CSS } from "@stitches/react";

interface Props {
  css: CSS;
}

const MintSparkle: React.FC<Props> = ({ css }) => {
  return (
    <Svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" css={css}>
      <path
        d="M2.2 4.4C3.41502 4.4 4.4 3.41502 4.4 2.2C4.4 0.98497 3.41502 0 2.2 0C0.98497 0 0 0.98497 0 2.2C0 3.41502 0.98497 4.4 2.2 4.4Z"
        fill="#42DCCC"
      />
    </Svg>
  );
};

const Svg = styled("svg", {
  position: "absolute",
  transform: "translate(-50%,-50%)",
});

export default MintSparkle;
